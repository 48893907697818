// custom.scss
// Import Bootstrap's SCSS
// New color palette

$primary: #5089a2;  // Base Color
$secondary: #6b9eb2; // Slightly lighter
$success: #a0c3cf;  // Softer tone for success
$danger: #2e5e75;  // Darker shade for contrast
$light: #b0d0de;   // Very light version for backgrounds
$dark: #2a4f61;   // Deep dark variant for text
$custom: red;
$accordion-button-bg: $success;
$accordion-button-active-bg: $secondary;
$accordion-button-color: var(--bs-info-text-emphasis);
$accordion-button-active-color: white;
$btn-hover-bg-shade-amount: 20%;
$btn-hover-bg-tint-amount: 20%;

// Custom button styles
.btn-info {
    background-color: #cfe2ff !important;
    border-color: #0dcaf0 !important;


  &:hover, &:focus, &:active {
    background-color: #ddeafd;
  }
}

.btn-outline-info {
    background-color: white !important;
    border-color: #0dcaf0 !important;
    color: black !important;

    &:hover, &:focus, &:active {
        background-color: #cfe2ff !important;
        color: black !important;
      }
}

.container {
    max-width: 1000px !important; // Use !important to override default Bootstrap styles
    
  }

.custom-card {
  width: 20rem;
  color: var(--bs-info-text-emphasis) !important;
  border-radius: 12px;
  border-color: #cfe2ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0px;
  margin: 10px;
}

.custom-form {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-label {
  color: var(--bs-info-text-emphasis) !important;
  font-weight: 400;
}

.accordion-item {
  background-color: transparent !important; /* Make Accordion.Item transparent */
}

.accordion {
  background-color: transparent !important; /* Make Accordion transparent */
}

.custom-accordion-body {
  background-color: rgb($light, 0.8);
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* Ensures padding doesn't affect height */

}

.center-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('imgs/final_2.png');
  background-size: 100%; /* Keeps the image at its natural size */
  background-repeat: no-repeat; /* Prevents tiling */
  background-position: center; /* Centers the image */
  // opacity: 0.5; /* Adjust opacity here */
  z-index: -1; /* Ensure that the image is behind the content */
}

.main-container {
  display: flex;
  background-image: url('imgs/final_2.png');
  background-size: 100%; /* Keeps the image at its natural size */
  background-repeat: no-repeat; /* Prevents tiling */
  background-position: center; /* Centers the image */
  // opacity: 0.5; /* Adjust opacity here */
  // z-index: -1; /* Ensure that the image is behind the content */
  background-attachment: fixed;
  box-sizing: border-box;
  padding-top: 30px;
}

.custom-container {
  background-color: rgb($light, 0.6);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.header-custom, .footer-custom {
  opacity: 0.9;
  color: var(--bs-info-text-emphasis) !important;
}

h3 {
  color: var(--bs-info-text-emphasis) !important; 
}




// $primary: #2e6d78;
// $secondary: #4e8490;
// $success: #d0dcdb;
// $danger: #25575f;
// $light: #6e9ba8;
// $dark: #1d4148;

// // Base Color Palette
// $base-color: #2e6d78;
// $light-tint-1: #4e8490;
// $light-tint-2: #6e9ba8;
// $dark-shade-1: #25575f;
// $dark-shade-2: #1d4148;

// // Analogous Colors
// $green-teal: #2d785f;
// $teal-blue: #2d6078;

// // Complementary Colors
// $warm-orange: #d07c3d;
// $muted-yellow: #c5a33b;

// // Accent Colors
// $soft-coral: #d46d5a;
// $bright-cyan: #58c2d1;

// // Override Bootstrap Variables
// $primary: $base-color;
// $secondary: $light-tint-1;
// $success: $bright-cyan;
// $danger: $warm-orange;
// $warning: $muted-yellow;
// $info: $light-tint-1;
// $light: $light-tint-2;
// $dark: $dark-shade-1;

// // Additional Custom Styles
// $btn-primary-bg: $primary;
// $btn-primary-border: $primary;

// $btn-secondary-bg: $secondary;
// $btn-secondary-border: $secondary;

// $btn-success-bg: $success;
// $btn-danger-bg: $danger;
// $btn-warning-bg: $warning;
// $btn-info-bg: $info;

// // Add hover states for buttons
// $btn-primary-hover-bg: darken($primary, 10%);
// $btn-secondary-hover-bg: darken($secondary, 10%);
// $btn-success-hover-bg: darken($success, 10%);
// $btn-danger-hover-bg: darken($danger, 10%);
// $btn-warning-hover-bg: darken($warning, 10%);
// $btn-info-hover-bg: darken($info, 10%);

@import '~bootstrap/scss/bootstrap';
