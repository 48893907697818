.btn-custom {
  background-color: #cfe2ff !important;
}

.btn-logout {
  background-color: #cfe2ff !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

html, body {
  height: 100%; /* Ensures the body takes full height of the viewport */
  margin: 0; /* Removes default margins */
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the app fills the viewport height */
}

header {
  position: sticky;
  top: 0;
  z-index: 1020; /* Ensures it stays above other elements */
}

footer {
  position: sticky;
  bottom: 0; /* Stick the footer at the bottom */
  z-index: 1020; /* Ensure it stays above other content */
  width: 100%; /* Full width */
}

main {
  flex: 1; /* Allow the main section to grow and shrink dynamically */
  overflow-y: auto;
}

.footer-custom {
  margin-bottom: 0;
}
